@import url('https://fonts.googleapis.com/css?family=Poppins');

@font-face {
  font-family: 'conthrax-sb';
  src: local('conthrax-sb'), url(Fonts/conthrax-sb.ttf) format('truetype');
}

:root {
  cursor: default;

  --dark-background: #2d2926;
  --lighter-background: #b4b5b7;
  --light-text: #f4f5f0;
  --dark-text: #5c5f5f;
  --logo-red: #ef3340;
}

html,
body {
  overflow-x: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#doug {
  flex-direction: row-reverse;
}

#contact-text {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  letter-spacing: 0.1rem;
  font-size: 18px;
}

@media screen and (min-width: 700px) {
  #contact {
    width: 550px;
  }
  #contact-header {
    font-size: 34px;
    letter-spacing: 0.25rem;
  }
  #contact-icon {
    font-size: 65px;
  }
  #contact-text {
    font-size: 26px;
  }
}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  #doug {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.grow {
  transition: all 0.4s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
  opacity: 0.6;
}

@keyframes rollout {
  0% {
    transform: translateY(200px);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes rolldown {
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: none;
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
